import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Container,
  Section,
  Seo,
} from '@components'

import { OfferForm } from '../../../components/site/offer'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'

const offer = 'secrets_webinar'
const imgDir = '../../../components/site/images'

const Headline = () => {
  return (
    <Section>
      <Container className="mb-8 container-lg">
        <h1 className="mt-12 mb-4 text-3xl text-center">
          Best Practices For Secure API Access From Mobile Apps Without Exposing
          API Keys
        </h1>
        <h3 className="mt-4 mb-2 text-xl text-center text-gray-700">
          Webinar Recording: 9 June 2022
        </h3>
      </Container>
    </Section>
  )
}

const Pitch = () => {
  return (
    <div>
      <div className="mx-8 lg:ml-16 lg:mr-0">
        <StaticImage
          src={`${imgDir}/no-hardcoded-keys.png`}
          alt="No More Hardcoded API Keys"
          placeholder="blurred"
        />
      </div>
    </div>
  )
}

const Action = () => {
  return (
    <div>
      <h3 className="pl-4 pr-0 mt-4 mb-2 text-2xl text-center text-gray-700 lg:mt-0">
        Register Here To Watch Immediately
      </h3>
      <OfferForm offer={offer} />
    </div>
  )
}

const Details = () => {
  return (
    <Section>
      <Container className="mb-8 container-md">
        <div className="pt-0 pl-8 -mt-4">
          <p>
            Mobile apps increasingly depend on third-party API access, employing
            them for many reasons, including payment, location, social media and
            other services. Access is validated via API keys but these keys are
            being stolen, either from the mobile app code itself or from cloud
            repositories. If APIs are abused using keys you have not protected
            you could be exposed to financial losses, fines, and reputational
            damage due to:
          </p>
          <ul>
            <li>
              Hackers using your API keys to access a third-party service you
              pay for.
            </li>
            <li>
              Data breaches executed via third-party API traffic interception.
            </li>
            <li>
              DDoS attacks on APIs using your keys, causing services to stop
              responding either because service allocation limits are used up,
              or service rate limits are triggered.
            </li>
          </ul>
          <p>
            Michael Sampson, senior analyst at Osterman Research says “Our
            research shows that mobile apps depend on average on more than 30
            third-party APIs, and that half of the mobile developers we surveyed
            are still storing API keys in the app code. These two things
            together constitute a massive attack surface for bad actors to
            exploit. And third-party API threats against mobile apps aren’t as
            well understood by companies as they should be.“
          </p>

          <p>
            This webinar employs a live demo to show it is possible to avoid
            storing secrets in the mobile app code completely, eliminating any
            risk of extraction through code analysis, as well as the risk of
            exposure through accidental source code repository leaks.
          </p>

          <h3>What you will learn</h3>

          <ul>
            <li>
              The types of financial and reputational risks of using third-party
              APIs in mobile apps
            </li>
            <li>
              The pros and cons of direct API access from mobile apps vs access
              via backend proxies
            </li>
            <li>
              How to securely store keys and secrets in a way they cannot be
              stolen
            </li>
            <li>
              The particular challenges of using certificate pinning to protect
              the channel between apps and third-party APIs, and how to overcome
              these challenges and make operations easy with dynamic pinning
            </li>
            <li>
              Mitigation strategies for the situation when secrets are leaked:
              How to dynamically update keys to keep ahead of hackers
            </li>
          </ul>
        </div>
      </Container>
    </Section>
  )
}

const LandingPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>

      <Headline />

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col justify-center lg:flex-row lg:content-center lg:items-center">
            <div className="flex items-center justify-center lg:w-7/12">
              <Pitch />
            </div>

            <div className="lg:pl-8 lg:pr-0 lg:w-5/12">
              <Action />
            </div>
          </div>
        </Container>
      </Section>

      <Details />
    </LandingPageLayout>
  )
}

export default LandingPage
